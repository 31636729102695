import { slugify, defaultIfNull } from "./common";
import { get, pick } from "lodash";
import { languageOptions } from "../config/global";

const fillEmptyTranslations = (trans = {}) => {
  const translations = {};
  languageOptions.forEach((item) => {
    const lang = item.value;
    translations[lang] = {
      name: get(trans, `${lang}.name`, ""),
      slug: get(trans, `${lang}.slug`, ""),
      description: get(trans, `${lang}.description`, ""),
    };
  });
  return translations;
};

const prepareTranslations = (translations, onlyLang) => {
  let newTranslations = Object.assign({}, translations);
  Object.keys(newTranslations).forEach((lang) => {
    newTranslations[lang].slug =
      newTranslations[lang].slug || slugify(newTranslations[lang].name);
  });

  if (onlyLang) {
    newTranslations = pick(newTranslations, onlyLang);
  }

  return newTranslations;
};
class Product {
  constructor(data = {}, producer_id = null) {
    this.errors = {};
    this.id = get(data, "id", new Date().getTime());
    this.translations = fillEmptyTranslations(get(data, "translations", {}));
    this.category__macrocategory_id = get(
      data,
      "category__macrocategory_id",
      null
    );
    this.category__parent_id = get(data, "category__parent_id", null);
    this.category_id = get(data, "category_id", null);
    this.ageclass_id = get(data, "ageclass_id", 1);
    this.age_class_limits = this.ageclass_id !== 1;
    this.pw = get(data, "pw", false);
    this.pw_len = defaultIfNull(get(data, "pw_len", 1), 1);
    this.pw_unit = defaultIfNull(get(data, "pw_unit", 1), 1);
    this.extra = {
      transparency: {
        transparencyEcology: get(
          data,
          "extra.transparency.transparencyEcology",
          ""
        ),
        transparencyPrice: get(
          data,
          "extra.transparency.transparencyPrice",
          ""
        ),
        transparencySocial: get(
          data,
          "extra.transparency.transparencySocial",
          ""
        ),
      },
      pw_option: get(data, "extra.pw_option", 1),
      diet: {
        gluten_free: get(data, "extra.diet.gluten_free", false),
        vegan: get(data, "extra.diet.vegan", false),
        vegetarian: get(data, "extra.diet.vegetarian", false),
        lactose_free: get(data, "extra.diet.lactose_free", false),
      },
      flags: {
        price_foreach_variant: get(
          data,
          "extra.flags.price_foreach_variant",
          false
        ),
        min_availability_foreach_variant: get(
          data,
          "extra.flags.min_availability_foreach_variant",
          false
        ),
        weight_foreach_variant: get(
          data,
          "extra.flags.weight_foreach_variant",
          false
        ),
        origin_foreach_variant: get(
          data,
          "extra.flags.origin_foreach_variant",
          false
        ),
        perishability_foreach_variant: get(
          data,
          "extra.flags.perishability_foreach_variant",
          false
        ),
        stock_availability_foreach_variant: get(
          data,
          "extra.flags.stock_availability_foreach_variant",
          false
        ),
        selltime_limited_foreach_variant: get(
          data,
          "extra.flags.selltime_limited_foreach_variant",
          false
        ),
        images_foreach_variant: get(
          data,
          "extra.flags.images_foreach_variant",
          false
        ),
      },
    };

    this.certifications = get(data, "certifications", []);
    this.ptype_id = get(data, "ptype_id", false);
    this.producer_id = get(data, "producer_id", producer_id);
    this.is_active = get(data, "is_active", false);

    this.image = get(data, "image", null);
    this.image_label = get(data, "image_label", null);
  }

  isWeightProduct() {
    return this.extra.pw_option === 3;
  }

  prepare(editMode = false, onlyLang = undefined) {
    const data = {
      category__macrocategory_id: this.category__macrocategory_id,
      category__parent_id: this.category__parent_id,
      category_id: this.category_id,
      ptype_id: this.ptype_id,
      ageclass_id: this.ageclass_id,
      certifications: this.certifications,
      is_active: this.is_active,
      translations: prepareTranslations(this.translations, onlyLang),
      producer_id: this.producer_id,
      pw: this.isWeightProduct(),
      pw_len: this.pw_len,
      pw_unit: this.pw_unit,
      extra: { ...this.extra },
    };

    data.image = this.image !== null ? parseInt(this.image.id, 10) : null;
    data.image_label =
      this.image_label !== null ? parseInt(this.image_label.id, 10) : null;

    if (editMode) {
      data.id = this.id;
    }

    return data;
  }

  setErrors(errors) {
    this.errors = Object.assign({}, errors);
    return this.errors;
  }

  resetErrors() {
    this.errors = Object.assign({});
  }

  isValid(key) {
    const s =
      get(this.errors, key, undefined) !== undefined ? false : undefined;
    return s;
  }

  getErrorMessage(key) {
    const val = get(this.errors, key, undefined);
    if (val !== undefined) {
      return Array.isArray(val) ? val.join(" ") : val;
    }
    return "";
  }

  snapshot() {
    const snapshot = {
      id: this.id,
      is_active: this.is_active,
      translations: this.translations,
      category__macrocategory_id: this.category__macrocategory_id,
      category__parent_id: this.category__parent_id,
      category_id: this.category_id,
      ageclass_id: this.ageclass_id,
      age_class_limits: this.age_class_limits,
      pw: this.pw,
      pw_len: this.pw_len,
      pw_unit: this.pw_unit,
      extra: this.extra,
    };

    return JSON.parse(JSON.stringify(snapshot));
  }

  translationsSnapshot() {
    const snapshot = {
      translations: this.translations,
    };

    return JSON.parse(JSON.stringify(snapshot));
  }
}

export default Product;
